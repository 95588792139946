import React from "react";
import Components from "../components/components.js";
import SettingsContext from "../context/SettingsContext";
import SbEditable from "storyblok-react";
import {loadStoryblokBridge, getParam} from '../utils'
import LangContextProvider from "../context/LangContext";


const GATSBY_STORYBLOK_ACCESS_TOKEN = process.env.GATSBY_STORYBLOK_ACCESS_TOKEN //|| 'pvIHl8lTYepIdA4xXDxrWQtt'

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { story: null , centralData: null};
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
  }, GATSBY_STORYBLOK_ACCESS_TOKEN);
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam("slug"),
        version: "draft"      },
      data => {
        this.setState({ story: data.story });
      }
    );
    window.storyblok.get(
      {
        slug: "settings/settings",
        version: "draft"
      },
      data => {
        this.context.set(data.story);
      }
    );
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam("slug") });

    let sb = window.storyblok;

    sb.on(["change", "published"], payload => {
      this.loadStory(payload);
    });

    sb.on("input", payload => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        );
        this.setState({ story: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <div />;
    }
    const { content, lang, full_slug } = this.state.story


    return (
      <SbEditable content={content}>
        <LangContextProvider lang={lang} slug={full_slug}>
          <div>
            {React.createElement(Components(content.component), {
              key: content._uid,
              blok: content,
              story: this.state.story
            })}
          </div>
        </LangContextProvider>
      </SbEditable>
    );
  }
}

StoryblokEntry.contextType = SettingsContext;

export default StoryblokEntry;
